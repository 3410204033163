<!-- Start Navbar Area -->
<div class="navbar-area p-absolute">
    <div class="fiwan-responsive-nav">
        <div class="container">
            <div class="fiwan-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="fiwan-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Home</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 1</a></li>
                                <li class="nav-item"><a routerLink="/home" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 2</a></li>
                                <li class="nav-item"><a routerLink="/index-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 3</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Shop</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop Full Width <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop-full-width-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Full Width Style 01</a></li>
                                        <li class="nav-item"><a routerLink="/shop-full-width-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Full Width Style 02</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop Left Sidebar <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop-left-sidebar-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Left Sidebar Style 01</a></li>
                                        <li class="nav-item"><a routerLink="/shop-left-sidebar-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Left Sidebar Style 02</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop Right Sidebar <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop-right-sidebar-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar Style 01</a></li>
                                        <li class="nav-item"><a routerLink="/shop-right-sidebar-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar Style 02</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                                <li class="nav-item"><a routerLink="/wishlist" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wishlist</a></li>
                                <li class="nav-item"><a routerLink="/rooms" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Rooms</a></li>
                                <li class="nav-item"><a routerLink="/customer-service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Customer Service</a></li>
                                <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Account</a></li>
                                <li class="nav-item"><a routerLink="/order-tracking" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Order Tracking</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Product</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/simple-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Simple Product</a></li>
                                <li class="nav-item"><a routerLink="/slider-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Slider Product</a></li>
                                <li class="nav-item"><a routerLink="/sticky-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sticky Product</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/shop-left-sidebar-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Accessories</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Pages</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                                <li class="nav-item"><a routerLink="/error-404" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Blog</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                                <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>
                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    </ul>
                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <div class="search-box">
                                <i class='bx bx-search'></i>
                            </div>
                        </div>
                        <div class="option-item">
                            <a routerLink="/cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
                        </div>
                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            <div class="container">
                <div class="option-inner">
                    <div class="others-option d-flex align-items-center justify-content-center">
                        <div class="option-item">
                            <div class="search-box">
                                <i class='bx bx-search'></i>
                            </div>
                        </div>
                        <div class="option-item">
                            <a routerLink="/cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
                        </div>
                        <div class="option-item">
                            <div class="side-menu-btn" style="color:#000">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Search Overlay -->
<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Search Overlay -->
