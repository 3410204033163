import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from 'src/shared/data/data.service';
import { HttpClient } from '@angular/common/http';


@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
    @ViewChild('categoryList') categoryList: ElementRef;
    categories: any[] = [];
    constructor(
        public data: DataService,
        private http: HttpClient,
    ) {
        
     }
    ngOnInit(): void {
        this.fetchCategories();
    }
    fetchCategories(): void {
        this.http.post(this.data.base_url + 'product_menu/', {}).subscribe({
            next: (response: any) => {
                console.log('Received submenu data:', response.categories);
                this.categories=response.categories;           
            },
            error: (err) => {
                console.error('Error fetching submenu data:', err);
               
            }
        });
    }

    partnerSlidesOptions: OwlOptions = {
		loop: false,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: false,
		margin: 0,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 5
			},
			576: {
				items: 5
			},
			768: {
				items: 4
			},
			1024: {
				items: 8
			}
		}
    }

}
