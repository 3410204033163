<!-- Start Top Header Area -->
<!-- <section class="top-header-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-4">
                <ul class="top-header-contact-info">
                    <li><a href="+919092599111"><i class='bx bx-phone-call'></i> +91 9092599111</a></li>
                    <li><a href="mailto:info&#64;thetilestore.com">info&#64;thetilestore.com</a></li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-4"><input type="text" placeholder="Search" class="form-control" style="border: 1px solid #898989 !important;height:32px;border-radius: 10px;"></div>
            <div class="col-lg-4 col-md-4">
                <div class="top-header-right">
                    <ul class="d-flex align-items-center justify-content-end">
                        <li>
                            <div class="dropdown language-switcher d-inline-block">
                                
                                <div class="dropdown-menu" [class.active]="classApplied">
                                   
                                </div>
                            </div>
                        </li>
                        <li><a routerLink="/profile-authentication"><i class='bx bx-log-in'></i> Sign In</a></li>
                        <li><a routerLink="/profile-authentication"><i class='bx bx-user'></i> Sign Up</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Top Header Area -->

<!-- Start Navbar Area -->
<div class="navbar-area">
  <div class="fiwan-responsive-nav">
    <div class="container">
      <div class="fiwan-responsive-menu">
        <div class="logo">
          <a routerLink="/"><img src="assets/img/footer_logo.png" alt="logo" style="width:50px;height:50px"></a>
        </div>
      </div>
    </div>
  </div>

  <div class="fiwan-nav">
    <div class="container-fluid header">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="logo" routerLink="/home">
          <img src="assets/img/tile_logo.png">
          <!-- <div class="company-name">
            <span>The Tile</span>
            <span>Store</span>

          </div> -->
        </a>

        <div class="search-overlay-form">
          <form>
            <input 
            type="text" 
            class="input-search" 
            [placeholder]="currentPlaceholder"
            [(ngModel)]="searchTerm"
            name="search">
              <button type="submit"><i class='bx bx-search'></i></button>
          </form>
        </div>
        <div class="collapse navbar-collapse mean-menu">
          <ul class="navbar-nav">
          
            <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Products</a></li>


            <li class="nav-item"><a  class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Blog</a></li>
            <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Sign In</a></li>
            <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>
          </ul>

          <div class="others-option d-flex align-items-center">
         

            <div class="option-item">
              <a routerLink="/wishlist" class="cart-btn"><i class='bx bx-heart'></i></a>
            </div>
            <div class="option-item">
              <a routerLink="/cart" class="cart-btn"><i class='bx bx-cart'></i></a>
            </div>
           

            <div class="option-item">
              <!-- <div class="side-menu-btn"> -->
              <button type="button" class="enquire-btn" data-bs-toggle="modal" data-bs-target="#enquireModal"> <i class='bx bxs-paper-plane'></i> Enquire
                Now</button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div class="others-option-for-responsive">
    <div class="container">
      <div class="dot-menu">
        <div class="inner">
          <div class="circle circle-one"></div>
          <div class="circle circle-two"></div>
          <div class="circle circle-three"></div>
        </div>
      </div>

      <div class="container">
        <div class="option-inner">
          <div class="others-option d-flex align-items-center justify-content-center">
            <div class="option-item">
              <div class="search-box">
                <i class='bx bx-search'></i>
              </div>
            </div>

            <div class="option-item">
              <a routerLink="/cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
            </div>

            <div class="option-item">
              <div class="side-menu-btn">
                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Navbar Area -->

<!-- Search Overlay -->
<div class="search-overlay">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="search-overlay-layer"></div>
      <div class="search-overlay-layer"></div>
      <div class="search-overlay-layer"></div>

      <div class="search-overlay-close">
        <span class="search-overlay-close-line"></span>
        <span class="search-overlay-close-line"></span>
      </div>

      <div class="search-overlay-form">
        <form>
          <input type="text" class="input-search" placeholder="Search here...">
          <button type="submit"><i class='bx bx-search'></i></button>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- End Search Overlay -->
<div class="modal fade productsQuickView" id="enquireModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
        <span aria-hidden="true"><i class="bx bx-x"></i></span>
      </button>
      <div class="row align-items-center">
        <div class="col-lg-12 col-md-12">
          <div class="products-content">
            <h3>Enquire Now</h3>
            <p class="mb-4"><small class="text-muted">Fields marked with (<span style="color: red;">*</span>) are
                required</small></p>
            <form [formGroup]="enquiryForm">
              <div class="row">
                <!-- Name -->
                <div class="col-lg-6 col-md-6">
                  <div class="form-group mb-3">
                    <label for="name">Name <span style="color: red;">*</span></label>
                    <input formControlName="lead_name" class="form-control" id="name" type="text"
                      placeholder="Enter your name" />
                    <div *ngIf="enquiryForm.get('contact_name')?.invalid && enquiryForm.get('contact_name')?.touched"
                      class="text-danger">
                      Name is required.
                    </div>

                  </div>
                </div>

                <!-- Phone -->
                <div class="col-lg-6 col-md-6">
                  <div class="form-group mb-3">
                    <label for="phone">Phone <span style="color: red;">*</span></label>
                    <input formControlName="contact_phone" class="form-control" id="phone" type="tel"
                      placeholder="Enter your phone number" />
                    <div 
                      *ngIf="enquiryForm.get('contact_phone')?.touched && enquiryForm.get('contact_phone')?.invalid">
                      <div *ngIf="enquiryForm.get('contact_phone')?.invalid && enquiryForm.get('contact_phone')?.touched"
                      class="text-danger">
                      Phone Number is required.
                    </div>                      <div *ngIf="enquiryForm.get('phone')?.errors?.minlength">Phone number must be at least {{
                        enquiryForm.get('contact_phone')?.errors?.minlength.requiredLength }} characters long.</div>
                      <div *ngIf="enquiryForm.get('contact_phone')?.errors?.maxlength">Phone number cannot exceed 15 characters.
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Email -->
                <div class="col-lg-6 col-md-6">
                  <div class="form-group mb-3">
                    <label for="email">Email <span style="color: red;">*</span></label>
                    <input formControlName="contact_email" class="form-control" id="email" type="email"
                      placeholder="Enter your email" />
                    <div 
                      *ngIf="enquiryForm.get('contact_email')?.touched && enquiryForm.get('contact_email')?.invalid">
                      <div *ngIf="enquiryForm.get('contact_email')?.errors?.required" class="text-danger">Email is required.</div>
                      <div *ngIf="enquiryForm.get('contact_email')?.errors?.email">Please enter a valid email address.</div>
                    </div>
                  </div>
                </div>

                <!-- Types of Tiles -->
                <div class="col-lg-6 col-md-6">
                  <div class="form-group mb-3">
                    <label for="tileType">Types of Tiles</label>
                    <select formControlName="contact_name" class="form-control" id="tileType">
                      <option value="" disabled>Select Type</option>
                      <option value="glossy">Glossy Finish</option>
                      <option value="matte">Matte Finish</option>
                      <option value="textured">Textured</option>
                      <option value="antiskid">Anti-Skid</option>
                    </select>
                  </div>
                </div>

                <!-- Description -->
                <div class="col-lg-12 col-md-12">
                  <div class="form-group mb-3">
                    <label for="description">Description</label>
                    <textarea formControlName="reason_of_project" class="form-control" id="description" rows="3"
                      placeholder="Enter your message"></textarea>
                  </div>
                </div>

                <!-- Attachment -->
                <!-- <div class="col-lg-6 col-md-6">
                  <div class="form-group mb-3">
                    <label for="attachment">Attachment</label>
                    <input formControlName="rfq_file" type="file" class="form-control" id="attachment" />
                    <small class="text-muted">Upload images of tiles you're interested in (optional)</small>
                  </div>
                </div> -->
              </div>
            </form>

            <div class="products-add-to-cart">
              <button type="button" class="enquire-btn" (click)="addlead()"> <i class='bx bxs-paper-plane'></i> Enquire
                Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>