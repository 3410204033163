import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-trending-products',
    templateUrl: './trending-products.component.html',
    styleUrls: ['./trending-products.component.scss']
})
export class TrendingProductsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: "Premium Picks of the Week",
            paragraph: "Explore this week's most sought-after premium tiles, crafted for elegance and durability. From exquisite marble finishes to sleek, modern designs, our top selections bring sophistication to any space."
        }
    ]
    singleProductsBox = [
        {
            mainImg: 'assets/img/premium/blog1.jpg',
            title: 'Marble Pattern Wall Tiles',
            newPrice: '₹750.00',
            oldPrice: '',
            outOfStock: 'Out of Stock',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/premium/blog2.jpg',
            title: 'Kitchen Tiles',
            newPrice: '₹ 590.00',
            oldPrice: '₹ 710.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/premium/blog3.jpg',
            title: 'Elegant Bathroom Wall Tiles',
            newPrice: '₹ 750',
            oldPrice: '₹ 1000',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/premium/blog4.jpg',
            title: 'Custom WasBasin Tiles',
            newPrice: '₹ 400.00',
            oldPrice: '₹ 580.00',
            outOfStock: '',
            detailsLink: 'simple-product'
        }
    ]
    

    productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }

}
