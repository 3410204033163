import { Component } from '@angular/core';

@Component({
  selector: 'app-productpage',
  standalone: false,
  templateUrl: './productpage.component.html',
  styleUrl: './productpage.component.scss'
})
export class ProductpageComponent {
  pageTitle = [
    {
        bgImage: 'assets/img/page-title-bg.jpg',
        title: 'Ergonomic Desk Sofa'
    }
]

}
