import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-facility-style-two',
    templateUrl: './facility-style-two.component.html',
    styleUrls: ['./facility-style-two.component.scss']
})
export class FacilityStyleTwoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    facilityContent = [
        {
            icon: 'bx bx-shopping-bag',
            title: 'Shop Online',
            paragraph: 'Browse and purchase high-quality tiles from the comfort of your home with our seamless online shopping experience.'
        },
        {
            icon: 'bx bxs-plane-take-off',
            title: 'Free Shipping',
            paragraph: 'Enjoy free and reliable shipping on all tile orders, ensuring safe and timely delivery to your doorstep.'
        },
        {
            icon: 'bx bx-info-square',
            title: 'Return Policy',
            paragraph: 'We offer a hassle-free return policy, allowing you to shop with confidence and exchange or return tiles if needed.'
        },
        {
            icon: 'bx bx-check-shield',
            title: 'Secured Payments',
            paragraph: 'Your transactions are protected with advanced security measures, ensuring a safe and smooth checkout process.'
        }
    ];
    slider = [
        
        {
            image: 'assets/img/tiles-logo/Scratch-Resistant.svg',
            imageTitle: 'Anti-Skid',
        },
        {
            image: 'assets/img/tiles-logo/Scratch-Resistant.svg',
            imageTitle: 'Impact-Resistant',
        },
        {
            image: 'assets/img/tiles-logo/Scratch-Resistant.svg',
            imageTitle: 'Liquid-Repellent',
        },
        {
            image: 'assets/img/tiles-logo/Scratch-Resistant.svg',
            imageTitle: 'Lowest Maintenance',
        },
        {
            image: 'assets/img/tiles-logo/Scratch-Resistant.svg',
            imageTitle: 'Chemical-Stain Resistant',
        }
    ];


}
