import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-facility-style-one',
    templateUrl: './facility-style-one.component.html',
    styleUrls: ['./facility-style-one.component.scss']
})
export class FacilityStyleOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    facilityContent = [
        {
            icon: 'bx bx-ruler',
            title: 'Free Consultation',
            paragraph: 'Get expert advice from our design consultants on tile selection, material specifications, and quantity estimation for your project needs.'
        },
        {
            icon: 'bx bx-certification',
            title: 'Quality Assurance',
            paragraph: 'All our tiles undergo strict quality checks and come with manufacturer certification. We guarantee the authenticity and quality of every product.'
        },
        {
            icon: 'bx bx-car',
            title: 'Installation Support',
            paragraph: 'Connect with our network of certified installation professionals. We also provide detailed installation guidelines and maintenance tips.'
        },
        {
            icon: 'bx bx-package',
            title: 'Sample Service',
            paragraph: 'Request free tile samples to check the quality, color, and texture before making your final decision. Available for most collections.'
        }
    ]
    facilityImage = [
        {
            img: 'assets/img/about2.jpeg'
        }
    ]

}
