import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-popular-products',
    templateUrl: './popular-products.component.html',
    styleUrls: ['./popular-products.component.scss']
})
export class PopularProductsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: "Popular Tiles",
            paragraph: "Discover our most popular tiles that customers love!  The Carrara White Marble Tile brings a touch of luxury with its elegant veining, while the Wood-Look Porcelain Tile offers the warmth of natural wood with the durability of porcelain."
        }
    ]
    singleProductsBox = [
        {
            mainImg: 'assets/img/tile1.jpg',
            title: 'Carrara Marble Tile',
            newPrice: '₹18,000 per sq. meter',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/tile2.jpg',
            title: 'Wood-Look Porcelain Tile',
            newPrice: '₹1,500 per sq. meter',
            oldPrice: '₹1,800 per sq. meter',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/tile3.jpg',
            title: 'Glossy Metro Subway Tile',
            newPrice: '₹950 per sq. meter',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/tile4.jpg',
            title: 'Black Galaxy Granite Tile',
            newPrice: '₹3,500 per sq. meter',
            oldPrice: '₹3,800 per sq. meter',
            outOfStock: 'Out of Stock',
            detailsLink: 'simple-product'
        }
    ];


    productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }

}
