import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/shared/data/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';


export interface FAQ {
    faq_id: number;
    faq_question: string;
    faq_answer: string;
  }
@Component({
    selector: 'app-simple-product-page',
    templateUrl: './simple-product-page.component.html',
    styleUrls: ['./simple-product-page.component.scss'],
    animations: [
        trigger('fadeAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(10px)' }),
                animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
            ])
        ])
    ]
})

export class SimpleProductPageComponent implements OnInit, AfterViewInit {
    @ViewChild('mainImage') mainImageElement!: ElementRef;
    @ViewChild('zoomContainer') zoomContainer!: ElementRef;
    
    productId!: string;
    productDetails: any;
    tilesInfo: any;
    selectedImage: string = '';
    allProductImages: string[] = [];
    currentIndex: number = 0;
    activeTab: string = 'description';
    reviewForm: FormGroup;
    reviews: any[] = [];
    specifications: any[] = [];
    faqs: FAQ[] = [];    
    magnifierShow: boolean = false;
    magnifierPosX: number = 0;
    magnifierPosY: number = 0;
    zoomLevel: number = 2.5;
    
    tileInfo1 = {
        skuCode: '453',
        size: '300x600mm',
        packPerBox: '8',
        landingPricePerSQFT: '533.87',
        coveragePerPcs: '5',
        coveragePerBox: '15.5',
        usage: 'Wall',
        bodyType: 'VERTIFIED',
        brandName: 'KAJARIA',
        baseColor: 'White',
        finish: 'MATT',
        weightPerBox: '20',
        landingPerPCS: '821.5',
        landingPerBOX: '3375'
    };
    
    pageTitle = [
        {
            bgImage: 'assets/img/product-banner.PNG',
            title: ''
        }
    ];
    
    
    facilityContent = [
        {
            icon: 'bx bx-box',
            title: 'Applications',
            paragraph: [
              'Kitchen', 'Bathroom', 'Living Room', 'Hall', 'Foyer', 
              'TV Unit', 'Balcony', 'Other Highlighting Walls'
            ]
          },
          {
            icon: 'bx bx-certification',
            title: 'Maintenance',
            paragraph: [
              'Use sweep/mop/vacuum to clean tiles regularly',
              'Use mild detergent or ceramic cleaner',
              'Avoid high concentrated acid/cleaners on glazed tiles'
            ]
          },
          {
            icon: 'bx bx-car',
            title: 'Installation',
            paragraph: [
              'Certified professional installation',
              'Detailed installation guidelines',
              'Comprehensive maintenance tips',
              'Pre-installation tile inspection recommended'
            ]
          }
    ];
    
    
    constructor(
        private route: ActivatedRoute,
        private dataService: DataService,
        private fb: FormBuilder
    ) {
        this.reviewForm = this.fb.group({
            rating: [0, [Validators.required, Validators.min(1)]],
            title: ['', Validators.required],
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            content: ['', [Validators.required, Validators.minLength(20)]],
            saveInfo: [false]
        });
    }
    
    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.productId = params.get('id')!;
            this.loadProductDetails();
        });
    }
    
    ngAfterViewInit() {
        setTimeout(() => {
            this.setupMagnifier();
        }, 100);
    }
    
    setupMagnifier() {
        const mainImage = this.mainImageElement.nativeElement;
        
        mainImage.addEventListener('mousemove', (e: MouseEvent) => {
            if (!this.magnifierShow) return;
            
            const rect = mainImage.getBoundingClientRect();
            
            const x = (e.clientX - rect.left) / rect.width;
            const y = (e.clientY - rect.top) / rect.height;
            
            this.magnifierPosX = Math.max(0, Math.min(1, x - (0.5 / this.zoomLevel)));
            this.magnifierPosY = Math.max(0, Math.min(1, y - (0.5 / this.zoomLevel)));
        });
        
        mainImage.addEventListener('mouseenter', () => {
            this.magnifierShow = true;
        });
        
        mainImage.addEventListener('mouseleave', () => {
            this.magnifierShow = false;
        });
    }
    
    selectImage(imageUrl: string, index: number) {
        this.selectedImage = imageUrl;
        this.currentIndex = index;
    }
    
    prevImage() {
        this.currentIndex = this.currentIndex === 0 ? 
            this.allProductImages.length - 1 : 
            this.currentIndex - 1;
        
        this.selectedImage = this.allProductImages[this.currentIndex];
    }
    
    nextImage() {
        this.currentIndex = (this.currentIndex + 1) % this.allProductImages.length;
        this.selectedImage = this.allProductImages[this.currentIndex];
    }
    
    loadProductDetails(): void {
        this.dataService.getProductById(this.productId).subscribe({
            next: (product) => {
                if (product) {
                    console.log('1212',product)
                    // product.product_description = this.stripHtml(product.product_description);
                    this.productDetails = product.data;
                    this.pageTitle[0].title = product.data.product_name;
                    this.selectedImage = this.productDetails.product_image;
                    this.allProductImages = [
                        this.productDetails.product_image,
                        ...(this.productDetails.product_gallery || [])
                    ];
                    this.faqs=product.data.faqs;
                
                if (product.data.tiles_info) {                    
                    this.tilesInfo = product.data.tiles_info;
                }
                } else {
                    console.error('Product not found');
                }
            },
            error: (err) => console.error('Error loading product details', err)
        });
    }
    
    stripHtml(html: string): string {
        return html.replace(/<\/?[^>]+(>|$)/g, "");
    }
    
    setActiveTab(tabId: string) {
        this.activeTab = tabId;
    }
    
    setRating(rating: number): void {
        this.reviewForm.get('rating')!.setValue(rating);
    }
    
    
    submitReview(): void {
        if (this.reviewForm.valid) {
            const newReview = {
                name: this.reviewForm.value.name,
                avatarSrc: 'assets/img/default-avatar.jpg',
                date: new Date(),
                rating: this.reviewForm.value.rating,
                title: this.reviewForm.value.title,
                content: this.reviewForm.value.content
            };
            
            this.reviews.unshift(newReview);
            this.reviewForm.reset({
                rating: 0,
                saveInfo: this.reviewForm.value.saveInfo
            });
        }
    }
}
