import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/About.jpg',
            title: 'About Us'
        }
    ]

    aboutContent = [
        {
            subTitle: "ABOUT US",
            title: "Chennai's Premier Tile Destination",
            paragraph: "For over three decades, we have been Chennai's trusted name in premium tiles and architectural surfaces. From humble beginnings as a small tile shop in T. Nagar, we've grown into one of South India's most comprehensive tile showrooms, offering an extensive collection of domestic and imported tiles that blend traditional craftsmanship with contemporary design.",
            content: [
                {
                    title: "Our Goal",
                    paragraph: "We strive to be the one-stop destination for all tiling needs, offering unparalleled variety, quality, and customer service. Our goal is to help every customer find the perfect tiles that not only meet their functional requirements but also reflect their personal style and enhance their space's aesthetic appeal."
                },
                {
                    title: "Our Mission",
                    paragraph: "Our mission is to bring world-class tiling solutions to South Indian homes and businesses. We commit to sourcing the finest tiles from around the globe, providing expert guidance, and ensuring competitive pricing. We believe in building lasting relationships with our customers through transparency, quality assurance, and exceptional after-sales support."
                },
                {
                    title: "Our Vision",
                    paragraph: "We envision becoming South India's most innovative and customer-centric tile retailer. By continuously updating our collection with the latest global trends, investing in our showroom experience, and empowering our staff with product knowledge, we aim to revolutionize how people choose and buy tiles in Chennai and beyond."
                }
            ]
        }
    ]
    aboutImage = [
        {
            img: 'assets/img/about-img1.jpg'
        },
        {
            img: 'assets/img/about-img2.jpg'
        }
    ]

}
