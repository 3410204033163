import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';


@Component({
    selector: 'app-hometwo-banner',
    templateUrl: './hometwo-banner.component.html',
    styleUrls: ['./hometwo-banner.component.scss'],
})
export class HometwoBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerItem = [
        {
            title: 'Redefine Your Space',
            tagline: "Chennai's Biggest Tiles Showroom",
            subtitle: 'Premium Collection 2025',
            paragraph: 'Immerse yourself in a world of modern elegance with our latest designer tiles. Perfect for every home and office.',
            buttonText: 'EXPLORE COLLECTION',
            buttonLink: '/gallery',
            image: 'assets/img/slider/slider1.jpg',
            overlayColor: 'rgba(45, 55, 72, 0.7)'
        },
        {
            title: 'Artisan Crafted Designs',
            tagline: "Chennai's Biggest Tiles Showroom",
            subtitle: 'Handcrafted Excellence',
            paragraph: 'Experience the beauty of hand-finished tiles, where every pattern is a masterpiece of detail and craftsmanship.',
            buttonText: 'SHOP ARTISAN SERIES',
            buttonLink: '/gallery',
            image: 'assets/img/slider/slider7.jpg',
            overlayColor: 'rgba(44, 82, 130, 0.7)'
        },
        {
            title: 'Sustainable Luxury',
            tagline: "Chennai's Biggest Tiles Showroom",
            subtitle: 'Eco-conscious Elegance',
            paragraph: 'Style meets sustainability. Our eco-friendly tiles are designed to bring both beauty and responsibility to your spaces.',
            buttonText: 'VIEW ECO RANGE',
            buttonLink: '/gallery',
            image: 'assets/img/slider/video.jpeg',
            overlayColor: 'rgba(20, 83, 45, 0.7)'
        },
        {
            title: 'Timeless Aesthetics',
            tagline: "Chennai's Biggest Tiles Showroom",
            subtitle: 'Classic Meets Contemporary',
            paragraph: 'From vintage charm to modern sophistication, find tiles that perfectly match your unique taste and decor.',
            buttonText: 'DISCOVER STYLES',
            buttonLink: '/gallery',
            image: 'assets/img/slider/slider6.jpg',
            overlayColor: 'rgba(50, 50, 60, 0.7)'
        },
        {
            title: 'Elevate Your Interiors',
            tagline: "Chennai's Biggest Tiles Showroom",
            subtitle: 'Luxury in Every Detail',
            paragraph: 'Step into a world of refined designs, where every tile reflects unmatched elegance and superior quality.',
            buttonText: 'BROWSE COLLECTION',
            buttonLink: '/gallery',
            image: 'assets/img/slider/slider3.jpg',
            overlayColor: 'rgba(35, 90, 120, 0.7)'
        },
        {
            title: 'A Vision of Perfection',
            tagline: "Chennai's Biggest Tiles Showroom",
            subtitle: 'Curated for You',
            paragraph: 'Transform your spaces with our carefully curated selection of premium tiles, blending art with innovation.',
            buttonText: 'EXPLORE DESIGNS',
            buttonLink: '/gallery',
            image: 'assets/img/slider/showRoom2.jpeg',
            overlayColor: 'rgba(30, 60, 90, 0.7)'
        }
    ];

    homeSlidesOptions: OwlOptions = {
        loop: true,
        nav: true,
        margin: 0,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoHeight: true,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoplayHoverPause: true,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>"
        ],
        responsive: {
            0: { items: 1 },
            576: { items: 1 },
            768: { items: 1 },
            992: { items: 1 },
            1200: { items: 1 }
        }
    }

}
