import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-deal-in-this-week',
    templateUrl: './deal-in-this-week.component.html',
    styleUrls: ['./deal-in-this-week.component.scss']
})
export class DealInThisWeekComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    dealOfTheWeekContent = [
        {
            title: 'This Week’s Exclusive Deal!',
            paragraph: 'Upgrade your space with our premium tiles at unbeatable prices! For a limited time, enjoy massive savings on our best-selling designs. Don’t wait—grab your deal before it’s gone!',
            // discountPercent: 'Flat 25% OFF',
            // buttonIcon: 'bx bx-shopping-bag',
            // buttonText: 'SHOP NOW',
            // buttonLink: 'home'
        }
    ]
    
    dealOfTheWeekImage = [
        {
            img: 'assets/img/Website-Banners.jpg'
        }
    ]

     productsDetailsImageSlidesOptions: OwlOptions = {
            loop: true,
            nav: true,
            dots: false,
            autoplayHoverPause: true,
            autoplay: true,
            margin: 30,
            navText: [
                "<i class='bx bx-left-arrow-alt'></i>",
                "<i class='bx bx-right-arrow-alt'></i>"
            ],
            responsive: {
                0: {
                    items: 1,
                },
                576: {
                    items: 4,
                },
                768: {
                    items: 4,
                },
                1200: {
                    items: 3,
                }
            }
        }

}
