import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/shared/data/data.service';
import { apiResultFormat, productList } from 'src/shared/models/pages.model';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrl: './subcategory.component.scss'
})

export class SubcategoryComponent implements OnInit {
  subcatID!: string;
  subcategoryName: string = 'SubCategory Gallery';
  public productItems: productList[] = [];
  public selectedProduct: productList | null = null;
  pageTitle: { bgImage: string; title: string }[] = [];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    public data: DataService,
    private fb: FormBuilder
  ) {}

  loadProductDetails(): void {
    this.data.getsubcategoryById(this.subcatID).subscribe({
      next: (apiRes: apiResultFormat) => {
        if (apiRes?.data) {
          this.productItems = apiRes.data ?? [];
          this.updatePageTitle();
        }
      },
      error: (err) => console.error("Error loading data", err)
    });
  }

  stripHtml(html: string): string {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  }

  openQuickView(product: productList): void {
    this.selectedProduct = product;
  }

  updatePageTitle(): void {
    this.pageTitle = [
      {
        bgImage: 'assets/img/prod.jpg',
        title: this.subcategoryName
      }
    ];
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.subcatID = id;
        this.loadProductDetails();
      }
    });

    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['name']) {
        this.subcategoryName = queryParams['name'];
        console.log('Subcategory Name:', queryParams['name']);
      }
      this.updatePageTitle(); 
    });
  }
}