<app-navbar-style-two></app-navbar-style-two>
<app-partner></app-partner>
<!-- Start Page Title Area -->
<div class="page-title-area mt-0" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div>

<!-- End Page Title Area -->
<!-- Start Gallery Area -->
<section class="product-gallery pt-100 pb-70">
    <div class="container">
        <div class="row g-4">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let product of productItems;">
                <div class="product-card shadow-sm h-100">
                    <a [routerLink]="['/product', product.id]" class="product-link">
                        <div class="product-image-container">
                            <img [src]="product.product_image" alt="{{product.product_name}}" class="product-image img-fluid">
                            <div class="product-overlay">
                                <button class="btn btn-sm btn-light quick-view">Quick View</button>
                            </div>
                        </div>
                        <div class="product-info p-3">
                            <h5 class="product-name mb-2">{{ product.product_name }}</h5>
                            <div class="product-price-area d-flex justify-content-between align-items-center">
                                <span class="price-label">Price:</span>
                                <div class="price-amount">₹{{ product.product_cost_persqft }} <span class="unit">per sq.ft</span></div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- End Gallery Area -->
