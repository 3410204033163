<!-- product-nav.component.html -->
<div class="product-nav">
    <div class="category-container">
      <ul class="category-list">
        <li *ngFor="let category of categories" class="category-item">
          <a href="#" class="category-link">{{ category.name }}</a>
          <div class="dropdown-content">
            <ul class="subcategory-list">
              <li *ngFor="let subcategory of category.subcategories" class="subcategory-item">
                <a [routerLink]="['/subcategory', subcategory.id]" [queryParams]="{ name: subcategory.name }" class="subcategory-link">
                  {{ subcategory.name }}
                </a>                
              </li>                         
            </ul>
          </div>
        </li>
      </ul>
     
    </div>
  </div>  