import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/shared/data/data.service';
import { apiResultFormat, productList } from 'src/shared/models/pages.model';

@Component({
    selector: 'app-new-products',
    templateUrl: './new-products.component.html',
    styleUrls: ['./new-products.component.scss']
})
export class NewProductsComponent implements OnInit {
    public productItems: productList[] = [];
    public selectedProduct: productList | null = null;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        public data: DataService,
        private fb: FormBuilder,
    ) {}

    private loadInitialData(): void {
        this.data.getProductList().subscribe({
            next: (apiRes: apiResultFormat) => {
                if (apiRes && apiRes.data) {
                    this.productItems = apiRes.data;
                    console.log('1131313',apiRes.data)
                }
            },
            error: (err) => console.error("Error loading data", err)
        });
    }
    openQuickView(product: productList): void {
        this.selectedProduct = product;
    }

    ngOnInit(): void {
        this.loadInitialData();
    }

    sectionTitle = [
        {
            title: "Best Selling Tiles",
            paragraph: "Discover our top picks! From the sleek durability of ceramic and porcelain tiles to the budget-friendly appeal of vinyl, and the timeless elegance of natural stone like marble and granite—find the perfect tiles to elevate your space effortlessly."
        }
    ];
    
}
