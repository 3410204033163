<!-- Start Subscribe Area -->
<section class="subscribe-area" *ngFor="let SubscribeContent of subscribeArea;" [ngStyle]="{'background-image': 'url(' + SubscribeContent.bgImage + ')'}">
    <div class="container">
        <div class="subscribe-content">
            <div class="section-title">
                <span class="sub-title">{{SubscribeContent.subTitle}}</span>
                <h2>{{SubscribeContent.title}}</h2>
                <p class="description">Stay updated with our latest news and offers.</p>
            </div>

            <form class="newsletter-form" (ngSubmit)="onSubmit()" #subscribeForm="ngForm">
                <div class="input-group">
                    <span class="icon"><i class='bx bx-envelope-open'></i></span>
                    <input 
                        type="email" 
                        class="input-newsletter" 
                        placeholder="Enter your email address" 
                        name="email" 
                        required 
                        autocomplete="off"
                        [(ngModel)]="email">
                </div>
                <button type="submit" class="subscribe-btn" [disabled]="isSubscribing">
                    <span *ngIf="!isSubscribing">Subscribe Now</span>
                    <span *ngIf="isSubscribing">Subscribing...</span>
                </button>
            </form>

            <div class="alert alert-success" *ngIf="subscriptionSuccess">
                <i class='bx bx-check-circle'></i> Thank you for subscribing to our newsletter!
            </div>
            <div class="alert alert-danger" *ngIf="subscriptionError">
                <i class='bx bx-error-circle'></i> {{errorMessage}}
            </div>

            <div class="privacy-notice">
                <p>We respect your privacy. Unsubscribe at any time.</p>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->