<div class="container">
    <div class="section-title" *ngFor="let Content of sectionTitle;">
        <h2>{{Content.title}}</h2>
        <p>{{Content.paragraph}}</p>
    </div>

    <div class="row">
        <div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let Content of productItems;">
            <div class="single-products-item">
                <div class="products-image">
                    <a [routerLink]="['/product', Content.id]" class="d-block">
                        <img [src]="Content.product_image" alt="image">
                    </a>
                    <ul class="products-button">
                        <!-- Call openQuickView() when clicking on Quick View button -->
                        <li>
                            <a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView" (click)="openQuickView(Content)">
                                <i class='bx bx-show-alt'></i>
                            </a>
                        </li>
                        <li><a href="{{Content.product_image}}" class="popup-btn"><i class='bx bx-scan'></i></a></li>
                        <!-- <li><a routerLink="/{{Content.detailsLink}}"><i class='bx bx-link'></i></a></li> -->
                    </ul>
                </div>
                <div class="products-content">
                    <h3><a routerLink="/{{Content.detailsLink}}">{{Content.product_name}}</a></h3>
                    <div class="price">
                        <span class="new-price">{{Content.newPrice}}</span>
                        <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span>
                    </div>
                    <!-- <a routerLink="/wishlist" class="add-to-wishlist-btn"><i class="bx bx-heart"></i></a> -->
                </div>
                <span class="on-sale" *ngIf="Content.sale">{{Content.sale}}</span>
                <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div>
            </div>
        </div>
    </div>
</div>

<!-- Start QuickView Modal Area -->
<div class="modal fade productsQuickView" id="productsQuickView" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </button>
            <div class="row align-items-center" *ngIf="selectedProduct">
                <div class="col-lg-6 col-md-6">
                    <div class="products-image">
                        <img [src]="selectedProduct.product_image" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="products-content">
                        <h3>{{selectedProduct.product_name}}</h3>
                        <div class="price">
                            <span class="new-price">₹ {{selectedProduct.product_price}}</span>
                            <span class="old-price" *ngIf="selectedProduct.oldPrice">{{selectedProduct.oldPrice}}</span>
                        </div>
                        <p>{{selectedProduct.description}}</p>
                        <ul class="products-info">
                            <li><span>Vendor:</span> <a href="#">Lereve</a></li>
                            <li><span>Availability:</span> <a href="#">In stock (7 items)</a></li>
                            <li><span>Products Type:</span> <a href="#">Chair</a></li>
                            <li><span>Categories:</span> <a href="#">Chair</a></li>
                        </ul>
                        <div class="products-add-to-cart">
                            <div class="input-counter">
                                <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                <input type="text" value="1" min="1">
                                <span class="plus-btn"><i class='bx bx-plus'></i></span>
                            </div>
                            <button type="submit" class="default-btn"><i class='bx bx-cart'></i> ADD TO CART</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End QuickView Modal Area -->
