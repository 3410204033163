import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-why-choose-us',
    templateUrl: './why-choose-us.component.html',
    styleUrls: ['./why-choose-us.component.scss']
})
export class WhyChooseUsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    whyChooseContent = [
        {
            subTitle: 'WHY CHOOSE US',
            title: "Chennai's Most Trusted Tiles & Surfaces Destination",
            paragraph: 'With our extensive collection, expert guidance, and commitment to quality, we have become the preferred choice for architects, interior designers, and homeowners across Chennai and Tamil Nadu.',
            content: [
                {
                    title: 'Extensive Collection',
                    paragraph: 'Browse through over 10,000+ tile designs ranging from traditional South Indian patterns to contemporary Italian designs. Our showroom features everything from floor tiles, wall tiles, and bathroom tiles to specialized options like anti-skid tiles, pool tiles, and eco-friendly options. We regularly update our collection with the latest international trends.'
                },
                {
                    title: 'Competitive Pricing',
                    paragraph: 'As direct importers and bulk dealers, we offer the best market rates without compromising on quality. Our long-standing relationships with manufacturers worldwide enable us to provide premium tiles at competitive prices. We also offer special rates for bulk orders and project requirements.'
                },
                {
                    title: 'Expert Support & Service',
                    paragraph: 'Our experienced team provides expert guidance on tile selection, material consultation, and maintenance advice. We offer free samples, detailed product specifications, and virtual visualization tools to help you make informed decisions. Our dedicated after-sales team ensures smooth delivery and installation support.'
                }
            ]
        }
    ]
    whyChooseImage = [
        {
            img: 'assets/img/showRoom2.jpeg'
        }
    ]

}
