<!-- Start Modern Banner Area -->
<div class="modern-banner">
    <owl-carousel-o [options]="homeSlidesOptions">
        <ng-template carouselSlide *ngFor="let content of mainBannerItem">
            <div class="banner-slide" [ngStyle]="{'background-image': 'url(' + content.image + ')'}">
                <div class="overlay" [ngStyle]="{'background-color': content.overlayColor}"></div>
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-8">
                            <div class="banner-content" @fadeInOut>
                                <div class="showroom-tagline">Chennai's Biggest Tiles Showroom</div>
                                <span class="subtitle">{{content.subtitle}}</span>
                                <h1>{{content.title}}</h1>
                                <p>{{content.paragraph}}</p>
                                <div class="banner-btn">
                                    <a [routerLink]="content.buttonLink" class="primary-btn">
                                        {{content.buttonText}}
                                        <span class="btn-arrow"><i class="bx bx-right-arrow-alt"></i></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
<!-- End Modern Banner Area -->