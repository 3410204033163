import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    videoContent = [
        {
            bgImage: 'assets/img/video.jpeg',
            title: 'Explore Our Collections',
            paragraph: 'Discover our stunning range of designer tiles - from elegant marble-finish tiles to contemporary porcelain collections. Get inspired by our curated displays featuring the latest international designs, textures, and patterns for every space in your home.',
            videoLink: 'https://www.youtube.com/watch?v=bk7McNUjWgw'
        }
    ]

}
