import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map,Subscription,Subject  } from 'rxjs';
import { Router,ActivatedRoute,NavigationEnd } from '@angular/router';
import { apiResultFormat } from '../models/pages.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {
    base_url: string = 'http://thetilestore.in:8000/';
    image_url: string = 'http://thetilestore.in:8000/media/uploads/';
    parentSuccessMessage: string;
    parentErrorMessage: string;
    isModalOpen:boolean=false;
    alert:boolean=false;
  ealert:boolean=false;
  constructor(
    private http: HttpClient,
    private router: Router,) { }


    public closealert(){
        if(this.alert==true || this.ealert==true)
       {
         setTimeout(() => {
                if(this.ealert==false)
                {
                  this.hidemodal();
                  //window.location.reload();
                }
                this.alert=false;
                this.ealert=false;
                this.parentErrorMessage="";
                this.parentSuccessMessage="";

            }, 3000);

         setTimeout(() => {
                this.isModalOpen=true;
            }, 2000);
       }
      }
      public showmodal(){
        this.isModalOpen=true;
        // this.renderer.removeClass(this.el.nativeElement.querySelector('.show'), 'offcanvas-backdrop');
      }
       public hidemodal(){
        this.isModalOpen=false;
        this.alert=false;
      }
    public getProductList(): Observable<apiResultFormat> {
        return this.http.post<any>(this.base_url+'supplychain/product_data/',{offset:0,limit:12}).pipe(
          map((response: any) => {
            return response;
          })
        );
      }
    public getProductMenu(): Observable<apiResultFormat> {
        return this.http.post<any>(this.base_url+'product_menu/',{}).pipe(
          map((response: any) => {
            return response;
          })
        );
      }
      public getProductById(id: string): Observable<any> {
        return this.http.post<any>(this.base_url + 'supplychain/product_data/', { product_id: id }).pipe(
            map((response: any) => {
                // Find the product that matches the requested ID
                return response;
            })
        );
    }
      public getsubcategoryById(id: string): Observable<any> {
        console.log('call to data');
        return this.http.post<any>(this.base_url + 'supplychain/subcategory_products/', { subcategory_id: id }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }


}
