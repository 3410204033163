import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-testimonials',
    templateUrl: './testimonials.component.html',
    styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            subTitle: "TESTIMONIALS",
            title: "What Cliens Say About Us"
        }
    ]
    singleTestimonialsItem = [
        {
            feedback: "We were renovating our new apartment in Anna Nagar and needed premium tiles that would last long. The team helped us choose the perfect tiles for each room, considering our budget and style preferences. The digital visualization tool they used helped us imagine how the tiles would look in our space. Extremely satisfied with the service!",
            authorImg: 'assets/img/userAvatar.png',
            authorName: 'Rajesh Krishnan',
            authorDesignation: 'Interior Designer'
        },
        {
            feedback: "As an architect, I've been working with them for over 5 years now. Their collection of imported tiles is impressive, and they keep updating it with latest trends. Their team understands technical requirements well, and their bulk order pricing has helped me deliver many projects within budget.",
            authorImg: 'assets/img/userAvatar.png',
            authorName: 'Priya Raghavan',
            authorDesignation: 'Senior Architect'
        },
        {
            feedback: "We were looking for traditional-style tiles for our new house in ECR. They had an amazing collection of both modern and traditional designs. The sales team was patient in showing us multiple options and even provided samples to help us decide. Great experience overall!",
            authorImg: 'assets/img/userAvatar.png',
            authorName: 'Arun Sundaram',
            authorDesignation: 'Homeowner'
        },
        {
            feedback: "Excellent variety and professional service. We needed specific anti-skid tiles for our restaurant kitchen and they provided perfect solutions within our budget. Their after-sales support for maintenance guidance has been very helpful.",
            authorImg: 'assets/img/userAvatar.png',
            authorName: 'Meena Venkatesh',
            authorDesignation: 'Restaurant Owner'
        },
        {
            feedback: "Recently completed a large residential project using their tiles. Their bulk ordering system and timely delivery made the procurement process smooth. The quality of tiles and the range they offer is unmatched in Chennai.",
            authorImg: 'assets/img/userAvatar.png',
            authorName: 'Karthik Subramanian',
            authorDesignation: 'Construction Developer'
        }
    ]

    testimonialsSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		autoplay: true,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
                items: 1
			},
			576: {
                items: 1
			},
			768: {
                items: 1
			},
			992: {
                items: 1
			},
			1200: {
                items: 1
			}
		}
    }

}
