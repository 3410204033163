<app-navbar-style-two></app-navbar-style-two>
<app-partner></app-partner>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngIf="productDetails">
    <div class="page-title-overlay" [ngStyle]="{'background-image': 'url(' + productDetails.product_image + ')'}"></div>
    <div class="page-title-content">
        <div class="container">
            <h1>{{ productDetails.product_name }}</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active">{{ productDetails.sub_category }}</li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<!-- End Page Title Area -->

<!-- Start Products Details Area -->
<section class="products-details-area pt-100"  *ngIf="productDetails">
    <div class="container">
        <div class="row align-items-center">
            <!-- HTML -->
<div class="col-lg-5 col-md-12">
    <div class="products-details-image">
        <div class="product-view-container">
          <!-- Left Navigation Arrow -->
          <div class="nav-arrow left" (click)="prevImage()">
            <i class="bx bx-left-arrow-alt"></i>
          </div>
          
          <!-- Main Product Image -->
          <div class="main-image-wrapper">
            <a class="popup-btn main-image" #mainImage>
              <img [src]="selectedImage || productDetails.product_image" [alt]="productDetails.product_name">
              
              <!-- Magnifier Lens Overlay -->
              <div class="magnifier-lens" *ngIf="magnifierShow"></div>
            </a>
            
            <!-- Magnified Image Container -->
            <div class="zoom-container" *ngIf="magnifierShow" #zoomContainer
                 [style.background-image]="'url(' + (selectedImage || productDetails.product_image) + ')'"
                 [style.background-position]="(magnifierPosX * 100) + '% ' + (magnifierPosY * 100) + '%'"
                 [style.background-size]="(zoomLevel * 100) + '%'">
            </div>
          </div>
          
          <!-- Right Navigation Arrow -->
          <div class="nav-arrow right" (click)="nextImage()">
            <i class="bx bx-right-arrow-alt"></i>
          </div>
        </div>
        
        <!-- Thumbnail Strip -->
        <div class="thumbnail-strip">
          <div class="thumbnail-item" *ngFor="let image of allProductImages; let i = index" 
               [class.active]="selectedImage === image" 
               (click)="selectImage(image, i)">
            <img [src]="image" [alt]="'View ' + (i+1)">
          </div>
        </div>
      </div>
</div>
<div class="col-lg-7 col-md-12">
    <div class="products-details-desc tile-product">
        <!-- Product Header -->
        <div class="product-header">
            <h3>{{ productDetails.product_name }}</h3>
            <div class="brand-badge">
                <span>{{  productDetails.brand }}</span>
            </div>
        </div>

        <!-- Price Section -->
        <div class="price-container">
            <div class="price">
                <span class="new-price">₹{{ productDetails.product_cost_persqft }}/Sq.Ft</span>
                <span></span>
                <span class="separator">Or</span>
                <span class="box-price">₹{{ productDetails.product_price_box }}/Box</span>
            </div>  
            <div class="stock-status in-stock">
                <i class='bx bx-check-circle'></i> In Stock
            </div>
        </div>
        

        <!-- Key Specifications -->
        <div class="key-specs">
            <div class="spec-item">
                <span class="spec-icon"><i class='bx bx-shape-square'></i></span>
                <span class="spec-label">Size</span>
                <span class="spec-value">{{this.tilesInfo.tile_size}}</span>
            </div>
            <div class="spec-item">
                <span class="spec-icon"><i class='bx bx-package'></i></span>
                <span class="spec-label">Pack/Box</span>
                <span class="spec-value">{{ productDetails.box_no}}</span>
            </div>
            <div class="spec-item">
                <span class="spec-icon"><i class='bx bx-ruler'></i></span>
                <span class="spec-label">Coverage/Box</span>
                <span class="spec-value">{{this.tilesInfo.coverage_box }} SQFT</span>
            </div>
        </div>

        <!-- Technical Specifications -->
        <div class="tile-specs">
            <h4>Technical Specifications</h4>
            <div class="specs-grid">
                <div class="spec-row">
                    <div class="spec-name">SKU</div>
                    <div class="spec-value">{{  this.tilesInfo.product_code }}</div>
                </div>
                <div class="spec-row">
                    <div class="spec-name">Tile Use</div>
                    <div class="spec-value">{{  this.tilesInfo.tile_use }}</div>
                </div>
                <div class="spec-row">
                    <div class="spec-name">Thickness</div>
                    <div class="spec-value">10 mm</div>
                </div>
                <div class="spec-row">
                    <div class="spec-name">Coverage per Piece</div>
                    <div class="spec-value">{{  this.tilesInfo.coverage_tile }} Sq.Ft</div>
                </div>
                <div class="spec-row">
                    <div class="spec-name">Coverage per Box</div>
                    <div class="spec-value">{{  this.tilesInfo.coverage_box }} Sq.Ft</div>
                </div>
                <div class="spec-row">
                    <div class="spec-name">Weight Per Box</div>
                    <div class="spec-value">{{  this.tilesInfo.weight_per_box }} Kg</div>
                </div>
                <div class="spec-row">
                    <div class="spec-name">Color</div>
                    <div class="spec-value">{{  this.tilesInfo.base_color }}</div>
                </div>
                <div class="spec-row">
                    <div class="spec-name">Finish</div>
                    <div class="spec-value">{{  this.tilesInfo.tile_finish }}</div>
                </div>
                <div class="spec-row">
                    <div class="spec-name">Body Type</div>
                    <div class="spec-value">{{  this.tilesInfo.body_type }}</div>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="products-add-to-cart">
            <button type="submit" class="default-btn" data-bs-toggle="modal" data-bs-target="#enquireModal">
                <i class='bx bxs-paper-plane'></i> Enquire Now
            </button>
            <!-- <button type="button" class="sample-btn">
                <i class='bx bx-image'></i> Request Sample
            </button> -->
        </div>
        
        
        
        
        <div class="products-share">
            <ul class="social">
                <li><span>Share:</span></li>
                <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
            </ul>
        </div>
        
    </div>
</div>

<div class="product-description-container">
   
    
    <div class="care-instructions">
        <h3>Product Description</h3>
        <p>{{ productDetails.product_description }}</p>
        <div class="key-features">
            <h3>Product Highlights</h3>
            <ul class="features-list">
                <li>
                    <i class="bx bx-shield-alt-2"></i>
                    <strong>Exceptional Durability</strong>
                    Engineered to withstand heavy foot traffic and resist wear, ensuring long-lasting beauty and performance
                </li>
                <li>
                    <i class="bx bx-palette"></i>
                    <strong>Versatile Design</strong>
                    Available in multiple sizes, colors, and finishes to complement any interior design style
                </li>
                <li>
                    <i class="bx bx-water"></i>
                    <strong>Water-Resistant Technology</strong>
                    Advanced moisture protection makes these tiles ideal for bathrooms, kitchens, and outdoor spaces
                </li>
                <li>
                    <i class="bx bx-check-shield"></i>
                    <strong>Easy Maintenance</strong>
                    Simple cleaning and stain-resistant surface keep your tiles looking pristine with minimal effort
                </li>
            </ul>
        </div>

        <!-- <div class="technical-details">
            <h3>Technical Specifications</h3>
            <table class="specs-table">
                <tr>
                    <td>Material:</td>
                    <td>High-Grade Ceramic</td>
                </tr>
                <tr>
                    <td>Thickness:</td>
                    <td>10mm</td>
                </tr>
                <tr>
                    <td>Size Options:</td>
                    <td>30x30cm, 45x45cm, 60x60cm</td>
                </tr>
                <tr>
                    <td>PEI Rating:</td>
                    <td>Class 4 (Heavy Commercial Use)</td>
                </tr>
            </table>
        </div> -->

        <div class="care-instructions">
            <h3>Care & Maintenance</h3>
            <p>Maintain the beauty of your tiles with regular sweeping and occasional mopping using a pH-neutral cleaner. Avoid abrasive materials and harsh chemicals to preserve the tile's finish.</p>
        </div>
    </div>
</div>
        </div>
    </div>
</section>
<section *ngIf="faqs?.length" class="faq-area pt-100">
    <div class="container">
      <h4>Frequently Asked Questions</h4>   
      <div class="faq-accordion accordion" id="faqAccordion">
        <div *ngFor="let faq of faqs; let i = index" class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button"
              [ngClass]="{ 'collapsed': i !== 0 }"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse' + i"
              aria-expanded="{{ i === 0 ? 'true' : 'false' }}"
              [attr.aria-controls]="'collapse' + i"
            >
              {{ faq.question }}
            </button>
          </h2>
          <div
            [id]="'collapse' + i"
            class="accordion-collapse collapse"
            [ngClass]="{ 'show': i === 0 }"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              <p>{{ faq.answer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<!-- End Products Details Area -->
