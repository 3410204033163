<app-navbar-style-two></app-navbar-style-two>
<app-partner></app-partner>
<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area pt-100">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Your name">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">Name is required.</div>
                                            <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Your email address">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Your phone number">
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="6" class="form-control" placeholder="Write your message..."></textarea>
                                        <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> SEND MESSAGE</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h3>Our Address</h3>
                        <p>
                            S.No. 107/1, Chettiyar Agaram Road,<br>
                            Rajiv Gandhi Nagar,
                            Noombal Village,<br>
                            Vanagram, Chennai - 600077
                        </p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <h3>Contact</h3>
                        <p>Mobile: <a href="tel:+919092599111">+91 9092599111</a></p>
                        <p>E-mail: <a href="mailto:info&#64;thetilestore.com">info&#64;thetilestore.com</a></p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-time-five'></i>
                        </div>
                        <h3>Hours of Operation</h3>
                        <p>Monday - Friday: 09:00 - 20:00</p>
                        <p>Sunday & Saturday: 10:30 - 22:00</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3886.6403606924614!2d80.149327!3d13.058549!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526108ff352bb9%3A0x3570a5d70783502b!2sThe%20Tile%20Store%20(A%20Unit%20Of%20Advika%20Enterprises%20Pvt.%20Ltd)!5e0!3m2!1sen!2sin!4v1739336403563!5m2!1sen!2sin"></iframe>
</div>
<!-- End Map Area -->
