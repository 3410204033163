import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/shared/data/data.service';
import { apiResultFormat, productList } from 'src/shared/models/pages.model';
@Component({
    selector: 'app-gallery-page',
    templateUrl: './gallery-page.component.html',
    styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {
    public productItems: productList[] = [];
    public selectedProduct: productList | null = null;
    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        public data: DataService,
        private fb: FormBuilder,
    ) {}

    private loadInitialData(): void {
        this.data.getProductList().subscribe({
            next: (apiRes: apiResultFormat) => {
                if (apiRes && apiRes.data) {
                    this.productItems = apiRes.data;
                    console.log('2121212',this.productItems);
                }
            },
            error: (err) => console.error("Error loading data", err)
        });
    }
    openQuickView(product: productList): void {
        this.selectedProduct = product;
    }

    ngOnInit(): void {
        this.loadInitialData();
    }
    pageTitle = [
        {
            bgImage: 'assets/img/prod.jpg',
            title: ' Product Gallery'
        }
    ]
}
