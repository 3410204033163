<!-- Start Deal Of The Week Area -->
<section class="deal-of-the-week-area pt-100 bg-e8fdff">
    <div class="container">
        <div class="deal-of-the-week-content" *ngFor="let Content of dealOfTheWeekContent;">
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
            <span class="discount"><strong>{{Content.discountPercent}}</strong></span>
            <!-- <a routerLink="/{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a> -->
        </div>
        <div class="products-details-image-slides">
            <owl-carousel-o [options]="productsDetailsImageSlidesOptions">
                <ng-template carouselSlide>
                    <div class="image">
                        <img src="assets/img/products/tile1.jpg" alt="image">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="image">
                        <img src="assets/img/products/tile2.jpg" alt="image">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="image">
                        <img src="assets/img/products/tile3.jpg" alt="image">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="image">
                        <img src="assets/img/products/tile4.jpg" alt="image">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>

        <!-- <div class="deal-of-the-week-image text-center" *ngFor="let Image of dealOfTheWeekImage;">
            <img [src]="Image.img" alt="image">
        </div> -->
    </div>
</section>
<!-- End Deal Of The Week Area -->
