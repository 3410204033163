import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/shared/data/data.service';


@Component({
    selector: 'app-navbar-style-two',
    templateUrl: './navbar-style-two.component.html',
    styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {

    constructor(private fb: FormBuilder,
        public data: DataService,
        private http: HttpClient,


    ) {

        this.enquiryForm = this.fb.group({
          contact_name: ['', [Validators.required]],
          contact_phone: ['', [Validators.required]],
          contact_email: ['', [Validators.required]],
          lead_name: [''],
          reason_of_project: [''],
          created_by: ['1'],

        });
      }

      public enquiryForm!: FormGroup;
      url:string='';
      currentPlaceholder: string = 'Search here...';
      private currentIndex: number = 0;
  
      // Interval reference for cleanup
      private placeholderInterval: any;
      private currentPhrase: string = '';
  
  // Index tracking for phrases
  private currentPhraseIndex: number = 0;
  
  // Index tracking for characters in current phrase
  private currentCharIndex: number = 0;
  
  // Whether we're currently typing or erasing
  private isTyping: boolean = true;
  
  // Interval references for cleanup
  private typingInterval: any;
  
  // Speeds for typing and erasing (in milliseconds)
  private typingSpeed: number = 100;
  private erasingSpeed: number = 50;
  private pauseBeforeErasing: number = 2000;
  private pauseBeforeTyping: number = 500;
  
  // Search term
  searchTerm: string = '';
      
      // Search term
      placeholders: string[] = [
        'Search for 4x4 Tiles...',
        'Search for Bathroom Tiles...',
        'Search for Kitchen Tiles...',
        'Search for Floor Tiles...',
        'Search for Wall Tiles...',
        'Search for Ceramic Tiles...',
        'Search for Porcelain Tiles...'
      ];


      ngOnInit(): void {
        this.startTyping();
      }


    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    startTyping(): void {
      this.currentPhrase = this.placeholders[this.currentPhraseIndex].replace("Search for ", ""); // Remove "Search for " from the phrases
      this.currentPlaceholder = "Search for "; // Ensure the prefix stays static
      this.animateText();
    }
    
    animateText(): void {
      if (this.isTyping) {
        // Typing forward
        this.typingInterval = setTimeout(() => {
          this.currentPlaceholder = "Search for " + this.currentPhrase.substring(0, this.currentCharIndex + 1);
          this.currentCharIndex++;
    
          if (this.currentCharIndex < this.currentPhrase.length) {
            this.animateText();
          } else {
            // Finished typing, pause before erasing
            this.isTyping = false;
            setTimeout(() => {
              this.animateText();
            }, this.pauseBeforeErasing);
          }
        }, this.typingSpeed);
      } else {
        // Erasing
        this.typingInterval = setTimeout(() => {
          this.currentPlaceholder = "Search for " + this.currentPhrase.substring(0, this.currentCharIndex);
          this.currentCharIndex--;
    
          if (this.currentCharIndex >= 0) {
            this.animateText();
          } else {
            // Finished erasing, move to next phrase
            this.isTyping = true;
            this.currentPhraseIndex = (this.currentPhraseIndex + 1) % this.placeholders.length;
            this.currentPhrase = this.placeholders[this.currentPhraseIndex].replace("Search for ", ""); // Remove prefix for animation
    
            // Pause before typing next phrase
            setTimeout(() => {
              this.animateText();
            }, this.pauseBeforeTyping);
          }
        }, this.erasingSpeed);
      }
    }
      addlead() {
        console.log('fnction is triggered')
        console.log(this.enquiryForm.value)

        if (this.enquiryForm.invalid) {
          this.enquiryForm.markAllAsTouched();
          return;
        }

        if (this.enquiryForm.valid) {
            const leadData = this.enquiryForm.value;
            console.log('Lead Data:', leadData); // Debug log to verify data

            this.url = this.data.base_url + 'addlead/';
            //leadData.created_by = localStorage.getItem('staffid');

            const formData = new FormData();

            Object.keys(leadData).forEach((key) => {
                if(leadData[key]!='' && leadData[key]!=undefined)
                {
                  formData.append(key, leadData[key]);
                }
              });

            console.log('Form Data:', formData); // Debug log to check the FormData object

            this.http.post(this.url, formData).subscribe({
              next: (response: any) => {
                console.log('Response from server:', response); // Log server response
                alert('Thanks For Your Enquiry');
                window.location.reload();
              },
              error: (error: any) => {

                console.error('Error:', error); // Log error
               
              },
              complete: () => {
                console.log('Request complete');
              },
            });
          }

      }

      ngOnDestroy(): void {
        // Clear interval when component is destroyed to prevent memory leaks
        if (this.typingInterval) {
          clearInterval(this.typingInterval);
        }
      }


}
